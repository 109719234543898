import React from 'react';
import { graphql } from 'gatsby';

import ContactDetails from '../sections/contactDetails';
import Hero, { Text, Tagline, Title } from '../sections/hero/hero';
import Layout from '../components/layout/layout';
import Button from '../components/button';

export default function Contact({ location, data }) {
  const backgroundImage = data.background.childImageSharp.gatsbyImageData;

  return (
    <Layout
      title="Kontakt"
      tagline="Kontakt"
      description="Kontakt"
      pathname={location.pathname}
    >
      <Hero backgroundImage={backgroundImage}>
        <Tagline>Kontakt</Tagline>
        <Title>Wie kann ich helfen?</Title>
        <Text>
          Klicke auf "Termin buchen" und wähle einen Termin um eine
          Kalendereinladung mit einem Video-Call-Link zu erhalten.
        </Text>
        <Button href={data.jonas.bookingLink} target="_blank">
          Termin buchen
        </Button>
      </Hero>
      <ContactDetails
        title="Vereinbare jetzt einen Termin mit mir!"
        profiles={[data.jonas]}
      />
      <ContactDetails
        title="Du willst mit dem Digital Insurance Podcast kooperieren?"
        profiles={data.podcastTeam.nodes}
      />
    </Layout>
  );
}
export const query = graphql`
  query ContactImageQuery {
    background: file(relativePath: { eq: "backgrounds/bg-contact.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    jonas: teamJson(name: { eq: "Jonas Piela" }) {
      name
      bio
      focus
      city
      country
      bookingLink
      linkedin
      email
      image {
        childImageSharp {
          gatsbyImageData(quality: 90, width: 200, layout: CONSTRAINED)
        }
      }
    }
    podcastTeam: allTeamJson(
      filter: { unit: { eq: "podcast" } }
      sort: { order: ASC, fields: prio }
    ) {
      nodes {
        name
        bio
        focus
        city
        country
        bookingLink
        linkedin
        email
        image {
          childImageSharp {
            gatsbyImageData(quality: 90, width: 200, layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
